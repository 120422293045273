<template>
  <div class="simple-audio-container">
    <img v-if="playing" class="play-btn" :src="PauseIcon" alt="" @click="pauseAudio" />
    <img v-else class="play-btn" :src="PlayIcon" alt="" @click="playAudio" />
    <div ref="progress" class="progress-container" @click="handleClickProgress">
      <div class="progress-inner" :style="{ 'width': `${percent}%` }">
        <div class="dot" @mousedown="onmousedown"></div>
      </div>
    </div>
    <div class="time-container">{{ audioIns ? common.formatDuration(audioIns.currentTime) : 0 }}/{{ common.formatDuration(duration) }}</div>
  </div>
</template>

<script>
import PlayIcon from '@/assets/simpleAudio/play.png'
import PauseIcon from '@/assets/simpleAudio/pause.png'
export default {
  name: "SimpleAudio",
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      PlayIcon,
      PauseIcon,
      playing: false,
      percent: 0,
      duration: 0,
      audioIns: null,
      isLoaded: false,
      isMouseDown: false
    }
  },
  mounted() {
    this.initAudio()
    document.body.addEventListener('mousemove', this.mousemove)
    document.body.addEventListener('mouseup', this.mouseup)
  },
  destroyed() {
    if (this.playing) {
      this.pauseAudio()
    }
    this.audioIns = null
    document.body.removeEventListener('mousemove', this.mousemove)
    document.body.removeEventListener('mouseup', this.mouseup)
  },
  methods: {
    initAudio() {
      this.audioIns = new Audio()
      this.audioIns.src = this.src
      this.audioIns.addEventListener('loadeddata', () => {
        this.isLoaded = true
        this.duration = this.audioIns.duration
      })
      this.audioIns.addEventListener('timeupdate', () => {
        const currentTime = this.audioIns.currentTime
        let percent = (currentTime / this.duration) * 100
        if (percent > 100) {
          percent = 100
        } else if (percent < 0) {
          percent = 0
        }
        this.percent = percent
      })
      this.audioIns.addEventListener('ended', () => {
        this.playing = false
      })
    },
    // 播放音频
    async playAudio() {
      if (this.playing) return
      await this.audioIns.play()
      this.playing = true
    },
    // 暂停音频
    pauseAudio() {
      if (!this.playing) return
      this.audioIns.pause()
      this.playing = false
    },
    onmousedown() {
      this.isMouseDown = true
    },
    mousemove(e) {
      if (!this.isMouseDown) return
      this.setPercentByMouse(e)
    },
    mouseup(e) {
      if (!this.isMouseDown) return
      this.setPercentByMouse(e)
      this.audioIns.currentTime = this.percent * this.duration / 100
      this.isMouseDown = false
    },
    handleClickProgress(e) {
      this.setPercentByMouse(e)
      this.audioIns.currentTime = this.percent * this.duration / 100
    },
    // 根据鼠标事件设置进度条百分比
    setPercentByMouse(e) {
      const boundary = this.$refs.progress.getBoundingClientRect()
      const width = boundary.width
      const offsetX = e.pageX - boundary.x
      let percent = (offsetX / width) * 100
      if (percent < 0) {
        percent = 0
      } else if (percent > 100) {
        percent = 100
      }
      this.percent = percent
    }
  }
}
</script>

<style scoped lang="scss">
.simple-audio-container {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
}

.play-btn {
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  cursor: pointer;
}

.progress-container {
  flex: 1;
  height: 10px;
  border-radius: 5px;
  margin-left: 26px;
  margin-right: 14px;
  background: #E5E5E5;
  cursor: pointer;
  .progress-inner {
    position: relative;
    height: 100%;
    background: linear-gradient( 90deg, #6DBBFC 0%, #309AF2 100%);
    border-radius: 5px;
    .dot {
      position: absolute;
      right: -12px;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border: 1px solid #309AF2;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
    }
  }
}

.time-container {
  font-weight: 400;
  font-size: 16px;
  color: #333;
}
</style>