// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import {store} from './store/store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './style/element-variables.scss'
import App from './App'
import router from './router'
import request from './utils/https'
// import apiConfig from '../config/api.config'
import qs from 'qs'
import md5 from 'js-md5'
import common from './utils/utils.js'
import urls from './utils/urls.js'
import * as filters from './filters'
import lodash from 'lodash'

import LinkButton from '../src/components/common/LinkButton'
import xybutton from '../src/components/common/Xybutton'

Vue.config.productionTip = false
Vue.use(ElementUI, {fontFamily: '微软雅黑', size: 'small'})
Vue.prototype.$axios = request
// axios.defaults.baseURL = apiConfig.baseUrl// 配置接口地址
Vue.prototype.qs = qs
Vue.prototype.$md5 = md5
Vue.prototype.common = common
Vue.prototype.$urls = urls
Vue.prototype.$_ = lodash

Vue.component('LinkButton', LinkButton)
Vue.component('xybutton', xybutton)

/**
 * register global utility filters
 */
Object.keys(filters).forEach(key=>{
  Vue.filter(key, filters[key]);
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h=>h(App)
}).$mount('#app')
