import { render, staticRenderFns } from "./assignExam.vue?vue&type=template&id=a659c5c8&scoped=true"
import script from "./assignExam.vue?vue&type=script&lang=js"
export * from "./assignExam.vue?vue&type=script&lang=js"
import style0 from "./assignExam.vue?vue&type=style&index=0&id=a659c5c8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a659c5c8",
  null
  
)

export default component.exports