<template>
  <article class="exam-container">
    <RouterNav name="创建考试">
      <template slot="right">
        <div class="current-school">
          <span class="school-name">当前学校：{{ currentSchool.schoolName }}</span>
          <el-link type="primary" :underline="false" @click="handleShowChangeSchoolDialog">切换</el-link>
        </div>
      </template>
    </RouterNav>
    <div v-if="!noticeHidden || superAdmin !== '2'">
      <Explain :superAdmin="superAdmin" @noticeChange="noticeChange"></Explain>
    </div>
    <div v-else>

      <div class="nav-tabs">
        <div class="tabs-item">
          <div>模块</div>
          <Tab :list="moduleList" :index="moduleIndex" :type="3" @tabChange="moduleChange"></Tab>
        </div>
        <div class="tabs-item">
          <div>年级</div>
          <Tab :list="gradeModuleList" :index="gradeModuleIndex" :type="3" @tabChange="gradeModuleChange"></Tab>
        </div>
      </div>
      <section class="content">
        <transition-group v-if="list.length > 0" name="list-complete" tag="div">
          <div class="list" v-for="(item, index) in list" :key="item.id">
            <div class="list-content">
              <div class="left">
                <div>{{ item.shortName || item.name }}</div>
              </div>
              <div class="right">
                <div class="btn" @click="check(index)" v-if="item.groupType === 1">查看</div>
                <div :class="['btn', 'add-btn', item.selected ? 'selected-btn' : '']"
                     @click="addWork(index)">{{ item.selected ? '已添加' : '添加' }}
                </div>
              </div>
            </div>
          </div>
        </transition-group>
        <div v-else class="empty-content-container">
          <div class="icon-none"></div>
          <p>暂无数据</p>
        </div>
        <div class="disabled" v-if="disabled">没有更多了...</div>
      </section>
      <examTrolley @listState="listState" />

      <ChangeSchoolDialog ref="changeSchoolDialogRef" :school-list="schoolList" @change="onChangeSchool" />
    </div>
  </article>
</template>

<script>
import RouterNav from "@/components/RouterNav";
import Tab from '@/components/tab.vue'
import Explain from './components/Explain.vue'
import examTrolley from '@/components/examTrolley.vue'
import ChangeSchoolDialog from "@/views/exam/components/ChangeSchoolDialog.vue";
import {getListPaperGroup} from "@/api/exam";
import {sectionToGradeIdMap} from "@/utils/enum";

export default {
  name: 'assignExam',
  components: {
    ChangeSchoolDialog,
    RouterNav,
    Tab,
    Explain,
    examTrolley
  },
  data() {
    return {
      // subModuleList: [{id: 0, name: '通用试题'}, {id: 1, name: '专用试题'}],
      moduleList: [],
      moduleIndex: 0,
      gradeModuleIndex: 0,
      list: [],
      pageIndex: 1,
      pageSize: 20,
      disabled: false,
      noticeHidden: false,
      superAdmin: '0',
      schoolId: '',
      schoolList: []
    }
  },
  computed: {
    currentSchool() {
      return this.schoolList.find(item => item.schoolId === this.schoolId) || {}
    },
    gradeModuleList() {
      if (this.currentSchool.section === 1) {
        if (this.currentSchool.yearSystem === 1) {
          // 四年制
          return [{id: '', name: '全部'}, {id: 0, name: '六年级'}, {id: 1, name: '七年级'}, {id: 2, name: '八年级'}, {id: 3, name: '九年级'}]
        } else {
          // 三年制
          return [{id: '', name: '全部'}, {id: 1, name: '七年级'}, {id: 2, name: '八年级'}, {id: 3, name: '九年级'}]
        }
      } else if (this.currentSchool.section === 2) {
        return [{id: '', name: '全部'}, {id: 4, name: '高一'}, {id: 5, name: '高二'}, {id: 6, name: '高三'}]
      }
    }
  },
  mounted() {
    this.superAdmin = sessionStorage.getItem('superAdmin')
    this.noticeHidden = localStorage.getItem('noticeHidden')
    this.schoolId = sessionStorage.getItem('schoolIdForCreateExam') || sessionStorage.getItem('schoolId')

    this.init()
  },
  methods: {
    async init() {
      await this.getSchoolList()
      await this.getModuleList()
      this.getList()
    },
    // 超级教师权限下的学校列表
    async getSchoolList() {
      const res = await this.$axios({
        method: 'post',
        url: this.$urls.getSchoolList
      })
      console.log(res)
      const {state, data} = res
      if (state !== '11') return
      this.schoolList = data
      console.log(this.currentSchool)
    },
    // 模块列表
    async getModuleList() {
      const params = {
        channelKey: 'channel-EXAM_HOME1',
        gradeId: sectionToGradeIdMap[this.currentSchool.section],
        provinceId: this.currentSchool.provinceId,
        cityId: this.currentSchool.cityId,
        schoolId: this.schoolId
      }
      const res = await this.$axios({
        method: 'post',
        url: this.$urls.getFirstLevelModuleByChannel,
        data: params,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const { state, data = [] } = res
      if (state !== '11') return
      this.moduleList = data
    },
    noticeChange(hidden) {
      if (hidden) {
        localStorage.setItem('noticeHidden', 1)
      }
      this.noticeHidden = true
    },
    load() {
      this.pageIndex += 1
      this.getList()
    },
    listState() {
      const {examWorkList} = this.$store.state
      const {list} = this
      for (let i = 0, len = list.length; i < len; i += 1) {
        list[i].selected = false
        if (examWorkList[list[i].id]) {
          list[i].selected = true
          this.$set(this.list, i, list[i])
        }
      }
      this.$forceUpdate()
    },
    check(index) {
      const {list} = this
      const {id} = list[index]
      const {name} = list[index]
      this.$router.push({
        name: 'paperDetail',
        query: {
          id,
          name,
          type: 'exam'
        }
      })
    },
    addWork(index) {
      const {list} = this
      const {id} = list[index]
      const {selected} = list[index]
      const {type} = list[index]
      if (!selected) {
        const {examWorkList} = this.$store.state
        if (Object.keys(examWorkList).length > 0 && type !== examWorkList[Object.keys(examWorkList)[0]].type) {
          this.$alert('请布置相同类型的试题', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        const name = list[index].shortName || list[index].name
        const obj = {
          id,
          name,
          type,
          exam: true
        }
        this.$store.commit('listAdd', obj)
      } else {
        const obj = {
          id,
          exam: true
        }
        this.$store.commit('listDel', obj)
      }
      list[index].selected = !list[index].selected
      this.$forceUpdate()
    },
    moduleChange(index) {
      this.moduleIndex = index
      this.pageIndex = 1
      this.getList()
    },
    gradeModuleChange(index) {
      this.gradeModuleIndex = index
      this.pageIndex = 1
      this.getList()
    },
    getList() {
      this.loading = true
      const {id: gradeTypeId} = this.gradeModuleList[this.gradeModuleIndex]
      const {id: moduleId} = this.moduleList[this.moduleIndex]
      const params = { gradeTypeId, moduleId }
      getListPaperGroup(params).then(res => {
        this.loading = false
        const { state, data = [] } = res
        if (state !== '11') return
        this.list = data
        this.listState()
      }).catch(() => {
        this.loading = false
      })
    },
    handleShowChangeSchoolDialog() {
      this.$refs.changeSchoolDialogRef.show(this.schoolId)
    },
    async onChangeSchool(schoolId) {
      this.schoolId = schoolId
      this.moduleIndex = 0
      this.gradeModuleIndex = 0
      this.list = []
      await this.getModuleList()
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-container {
  overflow: auto;
  height: calc(100vh - 80px);
  //position: relative;
}

.current-school {
  height: 54px;
  font-size: 15px;
  line-height: 54px;
  margin-right: 20px;
  .school-name {
    font-weight: bold;
    margin-right: 10px;
  }
}

.title {
  height: 84px;
  line-height: 84px;
  padding-left: 28px;
  font-size: 20px;
}

.nav-tabs {
  background-color: #fff;
  padding-top: 13px;
  padding-bottom: 13px;
  .tabs-item {
    display: flex;
    align-items: center;
    margin-left: 28px;
    padding-top: 10px;
    div {
      flex-shrink: 0;
      width: 40px;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}

.content {
  background-color: #fff;
  /*height: calc(100vh - 289px);*/
  overflow: auto;
  padding: 10px 28px;
  margin-top: 10px;

  .list {
    padding: 0 28px;
    border-radius: 8px;
    transition: transform .5s;

    &:hover {
      background-color: #F6F6F6;
    }

    .list-content {
      @include flex(space-between);
      height: 88px;
      border-bottom: 1px solid #F1F1F1;
      @include font;

      .num {
        @include font(14px, #999);
        margin-top: 10px;
      }

      .right {
        @include flex;

        .num {
          @include font(14px, #309AF2);
          margin-right: 14px;
        }

        .btn {
          width: 67px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #E5E5E5;
          border-radius: 15px;
          @include font(13px, #999999, center);
          cursor: pointer;

          &:hover {
            color: #333333;
          }
        }

        .add-btn {
          color: #309AF2;
          border-color: #309AF2;
          margin-left: 28px;

          &:hover {
            background-color: #309AF2;
            color: #fff;
          }
        }

        .selected-btn {
          border-color: transparent;
        }
      }
    }
  }
}

.disabled {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #999;
  text-align: center;
}

::v-deep .tabs {
  padding-left: 10px;
  .tabs-radio {
    li {
      margin-top: 0;
    }
  }
}

.empty-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon-none {
    width: 173px;
    height: 155px;
    background: url("../../assets/icon-classes-none.png") no-repeat;
    background-size: 100% 100%;
    margin: 30px 0;
  }
  p {
    color: #999;
    //font-size: 14px;
    margin-bottom: 20px;
  }
}
</style>
