import common from '../utils/utils.js'

export default {
  globalData: {
    choiceWorkList: [],
    selectedWorkList: [],
    menuList: [
      {
        id: '1',
        name: '首页',
        type: 'home'
      },
      {
        id: '2',
        name: '作业',
        type: 'work',
        child: [
          {id: 21, name: '布置作业', type: 'assignWork'},
          {id: 22, name: '检查作业', type: 'checkWork'}
        ]
      },
      {
        id: '4',
        name: '考试',
        type: 'exam',
        child: [
          {id: 41, name: '机房考试', type: 'officialExam'},
          {id: 42, name: '线上模考', type: 'checkExam'}
        ]
      },
      {
        id: '3',
        name: '班级',
        type: 'class',
        // hidden: true,
        child: [
          {id: 31, name: '班级管理', type: 'ClassManage'},
          {id: 32, name: '班级管理', type: 'classManage'},
          {id: 33, name: '分组管理', type: 'groupManage', hidden: true}
        ]
      },
      {
        id: '6',
        name: '学情报告',
        type: 'report',
        child: [
          {id: 61, name: '作业报告', type: 'workReport'},
          {id: 62, name: '模考报告', type: 'examReport'}
        ]
      },
      {
        id: '5',
        name: '作业助手',
        type: 'assistant',
        admin: true,
        hidden: true,
        child: [
          {id: 51, name: '我的布置', type: 'userWork'},
          {id: 52, name: '按学校查看', type: 'checkBySchool'},
          {id: 53, name: '按教师查看', type: 'checkByTeacher'}
        ]
      }
    ],
    writtenArr: [],
    preliminWritten: [] // 笔试预选
  },

// 根据得分和总分 获取不同className
  getScoreClassName: (score, totalScore = 100) => {
    if (!totalScore) return 'error'
    const rate = (Number(score) / Number(totalScore)) * 100
    let className = ''
    if (rate >= 0 && rate < 60) {
      className = 'error'
    } else if (rate >= 60 && rate < 85) {
      className = 'waning'
    } else if (rate >= 85 && rate <= 100) {
      className = 'success'
    }
    return className
  },

  /**
   * 格式化时间
   * @param fmt 时间格式
   * @param date 时间
   * @returns {*} 格式化后的时间
   */
  formatDate: (fmt, date) => {
    let o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      'S': date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
      }
    }
    return fmt
  },
  /**
   * 处理时间展示
   */
  timeFormat: (time) => {
    let minute = Math.floor(time/60)
    if (minute < 10) { minute = '0' + minute }
    let second = Math.ceil(time%60)
    if (second < 10) { second = '0' + second }
    return minute + ':' + second;
  },

  getFlag: (type) => {
    let flag
    type += ''
    switch (type) {
      case '5': // 音标学习
        flag = 2
        break
      case '4': // 智能单词
        flag = 7
        break
      case '6': // 课文听读
        flag = 3
        break
      case '8': // 时文阅读
        flag = 4
        break
      case '9': // 趣味配音
        flag = 9
        break
      case '14': // 假期作业
        flag = 14
        break
      case '12':
      case '11': // 我的教辅
      case '1':
      default:
        flag = 5
    }
    return flag
  },

  /**
   * 完成大题所需时间
   *
   */
  takeTime: (t) => {
    let time = 0
    switch (t.toString()) {
      case "112":
      case "136":
        time += 1;
        break;
      case "43":
      case "57":
      case "135":
      case "118":
      case "65":
      case "140":
      case "141":
      case "142":
      case "143":
        time += 2;
        break;
      case "125":
      case "165":
      case "76":
      case "77":
      case "79":
      case "34":
      case "148":
      case "48":
      case "49":
      case "101":
      case "105":
      case "113":
      case "69":
      case "70":
      case "150":
      case "151":
      case "153":
      case "154":
      case "162":
      case "159":
      case "156":
      case "139":
        time += 3;
        break;
      case "137":
      case "155":
      case "78":
      case "37":
      case "50":
      case "58":
      case "59":
      case "102":
      case "103":
      case "104":
      case "116":
      case "117":
        time += 4;
        break;
      case "168":
      case "41":
      case "38":
      case "46":
      case "67":
      case "138":
        time += 6;
        break;
      case "9":
      case "75":
      case "61":
      case "66":
        time += 7;
        break;
      case "126":
      case "131":
      case "40":
      case "130":
      case "127":
      case "158":
      case "35":
      case "149":
      case "114":
        time += 8;
        break;
      case "47":
        time += 9;
        break;
      case "8":
      case "166":
      case "144":
      case "147":
        time += 10;
        break;
      case "128":
      case "129":
        time += 12;
        break;
      case "167":
        time += 15;
        break;
      default:
        time += 5;
        break;
    }
    return time
  },

  /**
   * 笔试题所需时间
   * 选择题0.5分钟一个小问，
   * 完型填空0.5分钟一个小问，
   * 单项填空1分钟一个问，
   * 多项填空一分钟一个问，
   * 其他10分钟
   *
   */
  writtenTime: (type, lib) => {
    let time
    switch (type) {
      case '1':
      case '2':
        time = lib.length * 0.5
        break
      case '6':
      case '8':
        time = lib.length * 1
        break
      case '3':
      default:
        time = 10
        break
    }
    return time
  },

  gradeName: (val) => {
    const id = Number(val)
    switch (id) {
      case 0:
        return '六年级'
      case 1:
        return '七年级'
      case 2:
        return '八年级'
      case 3:
        return '九年级'
      case 4:
        return '高一'
      case 5:
        return '高二'
      case 6:
        return '高三'
      case 7:
        return '初中'
      case 8:
        return '高中'
      default:
        return ''
    }
  },

  // 指定排序的比较函数
  compare: (property) => {
    return function (obj1, obj2) {
      var value1 = obj1[property]
      var value2 = obj2[property]
      return value1 - value2 // 升序
    }
  },

  // 截取一定量字符串
  // a:截取标记 b:目标
  cutOut: (a) => {
    let t1 = a.replace(/(\n)+/g, '\n')
    let list = t1.split('\n')
    let arr = list.splice(0, 3)
    return arr.join('<br/>')
  },

  cutOut2: (a) => {
    let temp = a.replace(/<u>/g, "<span style='color:#309af2;'>")
    return temp.replace(/<\/u>/g, '</span>')
  },

  // 去评测文本
  evaluat: (text) => {
    if (!text) return ''
    const reg = /(\[.*?])|({.*?})/g
    const d = text.replace(reg, (word) => {
      const arr = word.split('/')
      return arr[0]
    })
    const regex = /@[^:]+:/g
    const l = d.replace(/[[{]/g, '')
    return l.replace(/\\/g, '/').replace(/’/g, '\'').replace(/[“”]/g, '"').replace(/[#\r]/g, '')
      .replace(/\n/g, '<br />').replace(regex, (match) => match.slice(1))
  },

  evaluat2: (t) => {
    let a = t.replace(/#/g, '')
    return a.replace(/＃/g, '')
  },

  cutHtml: (t) => {
    if (t) {
      let a = t.replace(/<i>/g, "<span style='font-style:italic;'>")
      let b = a.replace(/<\/i>/g, '</span>')
      return b.replace(/<\/blockquote>\n<blockquote>/g, '<br>')
    } else {
      return ''
    }
  },

  cutHtml4: (a) => {
    return a.replace(/\n\n\d{0,3}./g, '')
  },

  tHandle: (text) => {
    if (!text) return ''
    const reg = /<t>.*?<\/t>/g
    return text.replace(reg, () => {
      return `<span style="display:inline-block;width: 45px;border-bottom: 1px solid #000;text-align: center;"></span>`
    })
  },

  // 将中文单号转化为英文单引号
  rep: (t) => {
    return t.replace(/’/g, "'")
  },

  cutcut: (t) => {
    if (t) {
      return t.replace(/#/g, '<br/>')
    } else {
      return ''
    }
  },

  // 去重
  unique: (arr) => {
    return Array.from(new Set(arr))
  },

  uniqueForObj: (arr, key) => {
    const obj = {}
    const newArr = []
    for (let i = 0, len = arr.length; i < len; i += 1) {
      if (!obj[arr[i][key]]) {
        obj[arr[i][key]] = 1
        newArr.push(arr[i])
      }
    }
    return newArr
  },

  delHtmlTag: (str) => {
    const reg = /<\/?.+?\/?>/g
    return str.replace(reg, '')
  },

  binarySystem: (str) => {
    if (!str) return ''
    const newStr = str.replace(/-/g, '/')
    const time = new Date(newStr).getTime()
    const newTime = Math.floor(time / (1000 * 60 * 5)) * 5 * 60 * 1000
    return common.formatDate('yyyy/MM/dd hh:mm', new Date(newTime))
  },

  /**
   *  评分颜色逻辑
   *
   */
  scoreLevel: (score, char) => {
    if (score < 60.0) {
      return `<span class="col-FF3C30">${char}<\\span>`
    } else if (score < 85.0) {
      return `<span class="col-fed942">${char}<\\span>`
    }
    return `<span class="col-1DC060">${char}<\\span>`
  },

  /**
   * 短文、句子单个单词分数
   *
   */
  wordMate: (content, details) => {
    if (!content) return null
    const reg = /{.*?}/g
    const htmlReg = /<\/?.+?\/?>/g
    const t = content.replace(htmlReg, '').replace(/[‘’]/g, '\'')
    const text = t.replace(reg, (word) => {
      const arr = word.split('/')
      return arr[0]
    })
    const reg2 = /\[.*?]/g
    let newText = text.replace(reg2, (word) => {
      const arr = word.split('/')
      if (arr[1] === ']') {
        return arr[0]
      }
      return arr[1]
    }).replace(/[{[\]}]/g, '').replace(/\(.*?\)/g, '')
    let signIndex = content.length - 1
    for (let p = details.length - 1; p >= 0; p -= 1) {
      if (details[p].snt_details) {
        for (let l = details[p].snt_details.length - 1; l >= 0; l -= 1) {
          const sntDetails = details[p].snt_details[l]
          const char = common.scoreLevel(sntDetails.score, sntDetails.char)
          const startIndex = newText.lastIndexOf(sntDetails.char, signIndex)
          if (startIndex !== -1) {
            signIndex = startIndex
            const endIndex = startIndex + sntDetails.char.length
            newText = newText.slice(0, startIndex) + char + newText.slice(endIndex)
          }
        }
      } else {
        const wordName = details[p].char || details[p].wordName
        const char = common.scoreLevel(details[p].score, wordName)
        const startIndex = newText.lastIndexOf(wordName, signIndex)
        if (startIndex !== -1) {
          signIndex = startIndex
          const endIndex = startIndex + wordName.length
          newText = newText.slice(0, startIndex) + char + newText.slice(endIndex)
        }
      }
    }
    newText = newText.replace(/\\/g, '/')
    return newText
  },

  throttle: (func, delay) => {
    let prev = Date.now()
    return function () {
      const context = this
      const args = arguments
      const now = Date.now()
      if (now - prev >= delay) {
        func.apply(context, args)
        prev = Date.now()
      }
    }
  },

  /**
   *  数组元素交换位置
   *
   */
  swapArray: (arr, index1, index2) => {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0]
    return arr
  },

  /**
   * UUID
   */
  getUUID: (len) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    const uuid = []
    const radix = chars.length
    if (len) {
      for (let i = 0; i < len; i += 1) uuid[i] = chars[0 | Math.random() * radix]
    } else {
      // rfc4122, version 4 form
      let r

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
      uuid[14] = '4'

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (let i = 0; i < 36; i += 1) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16
          uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
        }
      }
    }
    return uuid.join('')
  },
  textEllipsis: (text, length, ellipsis) => {
    if (!text || text.length <= length) {
      return text
    }
    return text.substring(0, length) + (ellipsis && ellipsis.length ? ellipsis : '...')
  },
  validatePassword: (value) => /^\S*(?=\S{8,25})(?=\S*\d)(?=\S*[a-z])(?=\S*[\W._])\S*$/.test(value),
  /**
   * 格式化时间
   * @param duration
   * @returns {string}
   */
  formatDuration: (duration) => {
    if (typeof duration === 'number') {
      return [parseInt(((duration / 60) % 60).toString()), parseInt((duration % 60).toString())].join(':').replace(/\b(\d)\b/g, '0$1')
    }
  }
}
