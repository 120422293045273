<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="1100px"
    close-on-click-modal
    destroy-on-close
    @closed="close"
  >
    <div class="answer-detail-container">
      <div class="student-answer-container">
        <div class="student-answer-title">学生回答</div>
        <div :class="['score', scoreColor]">{{ typeof answerData.score === 'number' ? answerData.score.toFixed(1) : 0 }}/{{ answerData.totalScore }}分</div>
        <div :class="['student-answer-content', scoreColor]">
          <SimpleAudio v-if="isReading" :src="answerData.audioUrl" />
          <span v-else>{{ answerData.answer }}</span>
        </div>
      </div>
      <div v-if="isReading" v-html="content" class="content"></div>
    </div>
  </el-dialog>
</template>

<script>
import {readFile} from "@/utils/service";
import SimpleAudio from "@/components/SimpleAudio.vue";

export default {
  name: "AnswerDetailDialog",
  components: {SimpleAudio},
  data() {
    // 作答类型枚举
    const paperAnswerTypeMap = {
      READING: 'READING',
      CHOICE: 'CHOICE',
      BLANK: 'BLANK'
    }
    return {
      paperAnswerTypeMap,
      visible: false,
      title: '',
      answerData: {},
      content: ''
    }
  },
  computed: {
    isReading() {
      return this.answerData.paperAnswerType === this.paperAnswerTypeMap.READING
    },
    isChoice() {
      return this.answerData.paperAnswerType === this.paperAnswerTypeMap.CHOICE
    },
    isBlank() {
      return this.answerData.paperAnswerType === this.paperAnswerTypeMap.BLANK
    },
    scoreColor() {
      const scoreBgColorMap = {
        [this.paperAnswerTypeMap.READING]: () => {
          const percent = (this.answerData.score / this.answerData.totalScore) * 100
          return this.common.getScoreClassName(percent)
        },
        [this.paperAnswerTypeMap.CHOICE]: () => this.answerData.choice ? 'success' : 'error',
        [this.paperAnswerTypeMap.BLANK]: () => this.answerData.answers.includes(this.answerData.answer) ? 'success' : 'error',
      }
      const handler = scoreBgColorMap[this.answerData.paperAnswerType]
      return (handler && handler()) || ''
    }
  },
  methods: {
    show(answerData, row) {
      this.title = `${row.realName}的作答详情`
      this.answerData = this.$_.cloneDeep(answerData)
      this.initReadingData()
      this.visible = true
    },
    async initReadingData() {
      if (!this.isReading) return
      if (this.answerData.infoUrl) {
        const info = await readFile(this.answerData.infoUrl)
        this.answerData.ext = info.ext
        this.answerData.audioUrl = this.answerData.info.audioURL
      } else if (this.answerData.extUrl) {
        this.answerData.ext = await readFile(this.answerData.extUrl)
        console.log(this.answerData.ext)
      }
      const { refText, result } = this.answerData.ext
      const { details } = result
      this.content = this.common.wordMate(refText, details)
    },
    close() {
      this.answerData = {}
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  border-radius: 16px;
  .el-dialog__header {
    text-align: center;
    height: 78px;
    line-height: 78px;
    padding: 0 20px;
    border-bottom: 1px solid #E5E5E5;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }
  .el-dialog__body {
    padding: 40px;
  }
}


.student-answer-container {
  position: relative;
  width: 540px;
  height: 110px;
  padding: 20px;
  background-color: #F6F6F6;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin: 0 auto;
  overflow: hidden;
  .student-answer-title {
    flex-shrink: 0;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
  }
  .score {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    border-bottom-left-radius: 20px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: bold;
    &.error {
      background-color: #FF3C30;
    }
    &.waning {
      background-color: #fed942;
    }
    &.success {
      background-color: #1DC060;
    }
  }
  .student-answer-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    &.error {
      color: #FF3C30;
    }
    &.waning {
      color: #fed942;
    }
    &.success {
      color: #1DC060;
    }
  }
}

.content {
  margin-top: 10px;
  font-size: 20px;
  word-break: break-word;
}
</style>