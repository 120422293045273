<template>
  <div class="user-list" v-loading="loading">
    <el-table ref="tableRef" :data="list" border :max-height="height">
      <el-table-column label="排名" type="index" align="center" width="80" fixed>
        <template v-slot="{ row, $index }">{{ ($index + 1) + ((pageData.page - 1) * pageData.pageSize) }}</template>
      </el-table-column>
      <el-table-column label="学生姓名" prop="realName" align="center" width="100" fixed>
        <template v-slot="{ row }">{{ row.realName || '-' }}</template>
      </el-table-column>
      <el-table-column v-if="category === 2" label="班级" prop="realName" align="center" fixed>
        <template v-slot="{ row }">{{ row.className || '-' }}</template>
      </el-table-column>
      <el-table-column label="完成度" prop="finishRate" align="center" fixed>
        <template v-slot="{ row }">{{
            row.finishRate !== null ? row.finishRate : '-'
          }}{{ row.finishRate !== null ? '%' : '' }}
        </template>
      </el-table-column>
      <el-table-column label="完成时间" prop="finishTime" align="center" width="120" fixed>
        <template v-slot="{ row }">
          {{ row.finishTime !== null ? common.formatDate('MM/dd hh:mm', new Date(row.finishTime)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="总成绩" align="center" fixed>
        <template v-slot="{ row }">
          <span v-if="row.finishRate === null">-</span>
          <span v-else
                :class="[row.indexScoreRate > 85 ? 'col-1DC060' : row.indexScoreRate > 60 ? 'col-fed942' : 'col-FF3C30']">{{ row.scoreRate ? row.scoreRate.toFixed(1) : 0.0 }}分</span>
        </template>
      </el-table-column>
      <el-table-column v-for="(questionTypesItem, index) in initQuestionTypes" :key="index" :label="questionTypesItem.questionTypeName" align="center">
        <el-table-column v-for="(item, i) in questionTypesItem.itemList" :key="`${index}_${i}`" :label="item.itemName" :prop="item.key" align="center">
          <template v-slot="{ row }">
            <span v-if="item.itemName === '总成绩'">
              {{ Array.isArray(row.questionTypeList) ? showItemScore(row, index, item.key) : '-' }}
            </span>
            <el-link v-else-if="Array.isArray(row.questionTypeList)" type="primary" :underline="false" @click="handleShowAnswerDetail(row, index, item.key)">
              {{ showItemScore(row, index, item.key) }}
            </el-link>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="操作" align="center" width="220" fixed="right">
        <template v-slot="{ row, $index }">
          <template v-if="row.finishRate === null">
            <el-button v-if="row.homeworkState === '2'" type="text" size="medium" class="check-btn">已催交</el-button>
            <el-button v-else type="text" size="medium" class="check-btn" @click.stop="homeworkUrge($index, 1)">
              {{ category === 2 ? '催交考试' : '催交作业' }}
            </el-button>
          </template>
          <template v-else>
            <el-button type="text" size="medium" class="check-btn" @click="review($index)">点评学生</el-button>
            <el-button v-if="row.homeworkState === '1'" type="text" size="medium" class="check-btn col-FF3C30">已打回</el-button>
            <el-button v-else type="text" size="medium" class="check-btn col-FF3C30" @click="backHomework($index, 2)">打回重做
            </el-button>
            <el-button type="text" size="medium" class="check-btn" @click.stop="checkReport(row)">查看报告</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-20">
      <el-pagination
          :current-page.sync="pageData.page"
          :page-size="pageData.pageSize"
          :total="pageData.total"
          layout="total, prev, pager, next, jumper"
          @current-change="getStudentPage">
      </el-pagination>
    </div>

    <Dialog
        :dialogType="1"
        :name="dialogName"
        :id="dialogId"
        :list="dialogList"
        :visible="dialogVisible"
        @handleClose="handleClose"
        @addReview="addReview"
    ></Dialog>

    <AnswerDetailDialog ref="answerDetailDialogRef" />
  </div>
</template>

<script>
import Dialog from './Dialog.vue'
import {getItemStudentPage, urgeHomework} from "@/api/homework";
import AnswerDetailDialog from "@/views/homework/check/components/AnswerDetailDialog.vue";

export default {
  name: 'UserList',
  components: {
    AnswerDetailDialog,
    Dialog
  },
  props: ['itemId', 'name', 'paperGroupId', 'category', 'status', 'statusName', 'flag', 'sortType', 'sortBy', 'passageType', 'expireRedo', 'answerTimes', 'height', 'initQuestionTypes'],
  data() {
    return {
      loading: false,
      dialogId: '',
      dialogName: '',
      dialogVisible: false,
      dialogList: [],
      scrollTop: 0,
      list: [],
      pageData: {
        page: 1,
        pageSize: 20,
        total: 0
      },
    }
  },
  mounted() {
    console.log(this.$refs.tableRef)
    this.getStudentPage()
    const el = this.$refs.tableRef.$el
    const tableBody = el.getElementsByClassName('el-table__body-wrapper')[0]
    tableBody.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    getStudentPage() {
      const params = {
        itemId: this.itemId,
        needQuestionType: true,
        page: this.pageData.page,
        pageSize: this.pageData.pageSize
      }
      getItemStudentPage(params).then(res => {
        const { state, data } = res
        if (state !== '11') return
        const { pageContents = [], total } = data
        this.list = pageContents || []
        this.pageData.total = total || 0
        this.$nextTick(() => {
          const el = this.$refs.tableRef.$el
          const tableBody = el.getElementsByClassName('el-table__body-wrapper')[0]
          tableBody.scrollTo({
            top: 0
          })
        })
      })
    },
    showItemScore(row, index, key) {
      if (!Array.isArray(row.questionTypeList)) return '-'
      const questionTypeItem = row.questionTypeList[index]
      if (!questionTypeItem || !Array.isArray(questionTypeItem.itemList)) return '-'
      const item = questionTypeItem.itemList.find(item => item.key === key)
      return item && typeof item.itemScore === 'number' ? item.itemScore : '-'
    },
    handleShowAnswerDetail(row, index, key) {
      const questionTypeItem = row.questionTypeList[index]
      const item = questionTypeItem.itemList.find(item => item.key === key)
      console.log('handleShowAnswerDetail', item)
      this.$refs.answerDetailDialogRef.show(item.answerData, row)
    },
    handleScroll(e) {
      this.scrollTop = e.target.scrollTop
      this.$emit('onScrollTop', this.scrollTop)
    },
    // 催交
    homeworkUrge(index, type) {
      if (this.operateIntercept(type)) return
      this.loading = true
      const {list} = this
      const {id: studentId} = list[index]

      const params = {
        studentId,
        category: this.category,
        homeworkItemId: this.itemId,
        homeworkItemName: this.name
      }
      urgeHomework(params)
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              this.$set(this.list[index], 'popoverShow', false)
              this.$message({
                message: '催交成功',
                type: 'success'
              })
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 拦截判断 type: 1：催交；2：打回；
    operateIntercept(type) {
      // expireRedo: 过期补交, 1允许过期补交，2不允许过期补交
      // answerTimes	作答次数，1允许重复作答，2只能做一次
      // status	阶段状态 0去完成/未开始 1再做一次/进行中 2已过期/已结束
      // statusName	阶段状态名 未开始 进行中 已结束
      let bol = false
      // 允许重复作答+允许过期补交
      if (this.answerTimes === 1 && this.expireRedo === 1) {
        //  允许重复作答+不允许过期补交
      } else if (this.answerTimes === 1 && this.expireRedo === 2) {
        if (this.status == '2' || this.statusName === '已结束') {
          bol = true
          this.$alert('当前作业已结束且不允许补交，请修改设置后再进行此操作。', '提示', {
            confirmButtonText: '好的'
          })
        }
        //  不允许重复作答+允许过期补交
      } else if (this.answerTimes === 2 && this.expireRedo === 1) {
        if (type === 2) {
          bol = true
          this.$alert('当前作业不允许重复作答，请修改设置后再进行此操作。', '提示', {
            confirmButtonText: '好的'
          })
        }
        //  不允许重复作答+不允许过期补交
      } else if (this.answerTimes === 2 && this.expireRedo === 2) {
        if (type === 1) {
          if (this.status === '2' || this.statusName === '已结束') {
            bol = true
            this.$alert('当前作业已结束且不允许补交，请修改设置后再进行此操作。', '提示', {
              confirmButtonText: '好的'
            })
          }
        }
        if (type === 2) {
          bol = true
          this.$alert('当前作业不允许重复作答，请修改设置后再进行此操作。', '提示', {
            confirmButtonText: '好的'
          })
        }
      }
      return bol
    },
    // dialogVisible 切换
    handleClose() {
      this.dialogVisible = false
    },
    // 点评学生
    review(index) {
      const {list} = this
      const name = list[index].realName || list[index].name
      this.dialogName = name
      const {id} = list[index]
      this.dialogId = id
      const itemId = this.itemId || list[index].itemId
      this.reviewList(id, itemId)
    },
    reviewList(userId, itemId) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.reviewList,
        data: {
          itemId,
          userId,
          pageIndex: 1,
          pageSize: 10
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.dialogList = response.data
          this.dialogItemId = itemId
          this.dialogVisible = true
        }
      }).catch(() => {
        this.loading = false
      })
    },
    addReview(id, content) {
      this.loading = true
      const itemId = this.itemId || this.dialogItemId
      this.$axios({
        method: 'post',
        url: this.$urls.addReview,
        data: {
          itemId,
          userId: id,
          content
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.reviewList(id, itemId)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 打回重做
    backHomework(index, type) {
      if (this.operateIntercept(type)) return
      const {list} = this
      const {realName} = list[index]
      this.$confirm(`确认打回${realName}的作业？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.confirmBack(index)
      })
    },
    confirmBack(index) {
      this.loading = true
      const {list} = this
      const {id: userId} = list[index]
      const itemId = this.itemId || list[index].itemId
      this.$axios({
        method: 'post',
        url: this.$urls.backHomework,
        data: {
          itemId,
          userId
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.$set(this.list[index], 'homeworkState', '1')
          this.$set(this.list[index], 'popoverShow', false)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    popoverSwitch(index) {
      this.list[index].popoverShow = !this.list[index].popoverShow
    },
    // 查看报告
    checkReport(row) {
      row.workName = this.name
      sessionStorage.setItem('workStudentReport', JSON.stringify(row))
      if (this.flag === 9 || this.flag === 24) {
        this.$router.push({
          name: 'WorkStudentReportIframe',
          query: {
            classId: row.classId,
            title: row.workName,
            userId: row.id,
            itemId: this.itemId,
            flag: this.flag,
            workName: this.name
          }
        })
      } else {
        this.$router.push({
          name: 'WorkStudentReport',
          query: {
            userId: row.id,
            itemId: this.itemId,
            flag: this.flag
          }
        })
      }
    },
    answerDetail(index) {
      this.loading = true
      const {list} = this
      const {id: userId} = list[index]
      const {flag} = this
      const itemId = this.itemId || list[index].itemId
      let url
      const dataParam = {}
      dataParam.userId = userId
      if (flag === 3) {
        url = this.$urls.repeatAfterUnitById
        dataParam.itemId = itemId
        dataParam.id = this.paperGroupId
      } else if (this.category === 2) {
        url = this.$urls.homeworkExamAnswer
        dataParam.itemId = itemId
        dataParam.flag = flag
      } else if (this.itemId) {
        url = this.$urls.answerDetail
        dataParam.homeworkItemId = itemId
        dataParam.flag = flag
      }
      this.$axios({
        method: 'post',
        url,
        data: dataParam
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          if (data) {
            if (this.category === 2) {
              for (let i = 0, len = data.length; i < len; i += 1) {
                data[i].name = data[i].shortName
              }
            }
            this.$set(this.list[index], 'answerData', data)
            this.$set(this.list[index], 'answerShow', true)
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
    sort(name) {
      this.$emit('sort', name)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table--group, .el-table--border {
  border: 1px solid #EBEEF5;
  border-right: none;
  border-bottom: none;
}
</style>
