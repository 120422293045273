import request from "@/utils/https";

/**
 * 获取机房考试的学校列表
 * @param {Object} form
 * @param {Number} page
 * @param {Number} pageSize
 * @return {AxiosPromise}
 */
export function getOfficialSchoolList(form, page, pageSize = 20) {
  return request({
    url: '/apiv2/teacher/exam/getSchool',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form,
      page,
      pageSize
    }
  })
}

/**
 * 获取学校底下的考试列表 - 超级教师
 * @param {Object} form
 * @param {Number} page
 * @param {Number} pageSize
 * @return {AxiosPromise}
 */
export function getSuperOfficialList(form, page, pageSize = 20) {
  return request({
    url: '/apiv2/teacher/exam/findExamListBySchool',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form,
      page,
      pageSize
    }
  })
}

/**
 * 获取学校底下的考试列表 - 超级教师
 * @param {Object} form
 * @param {Number} page
 * @param {Number} pageSize
 * @return {AxiosPromise}
 */
export function getSuperOfficialListV2(form, page, pageSize = 20) {
  return request({
    url: '/apiv2/teacher/exam/findExamListBySchoolV2',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form,
      page,
      pageSize
    }
  })
}

/**
 * 获取学校底下的考试列表 - 普通教师
 * @param {Object} form
 * @param {Number} page
 * @param {Number} pageSize
 * @return {AxiosPromise}
 */
export function getNormalOfficialList(form, page, pageSize = 20) {
  return request({
    url: '/apiv2/teacher/exam/findExamListByClazz',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form,
      page,
      pageSize
    }
  })
}

/**
 * 获取学校底下的考试列表 - 普通教师
 * @param {Object} form
 * @param {Number} page
 * @param {Number} pageSize
 * @return {AxiosPromise}
 */
export function getNormalOfficialListV2(form, page, pageSize = 20) {
  return request({
    url: '/apiv2/teacher/exam/findExamListByClazzV2',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form,
      page,
      pageSize
    }
  })
}

/**
 * 获取学校下按年级进行分组的班级数据
 * @param {Object} data
 * @return {AxiosPromise}
 */
export function getGroupByGrade(schoolId) {
  return request({
    url: '/apiv2/teacher/clazz/findClazzGroupByGrade',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      schoolId
    }
  })
}

/**
 * 查询机房考试详情 - 学生列表
 * @param {String} batchNum
 * @param {String} clazzId
 * @param {String} groupId
 * @return {AxiosPromise}
 */
export function getStudentList(data) {
  return request({
    url: '/apiv2/teacher/exam/web/getStudentList',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

/**
 * 根据考试批次号查询班级报告
 * @param {String} clazzId
 * @param {String} batchNum
 * @return {AxiosPromise}
 */
export function getTotalStatistics(data) {
  return request({
    url: '/apiv2/teacher/exam/getTotalStatistics',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

/**
 * 超级教师-根据考试批次号查询班级报告
 * @param data
 * @returns {*}
 */
export function getSuperTotalStatistics(data = {}) {
  return request({
    url: '/apiv2/teacher/exam/getSuperTotalStatistics',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 查询机房考试详情 - 导出成绩表
 * @param {String} examId
 * @param {String} clazzId
 * @return {AxiosPromise}
 */
export function exportGradeTable(data) {
  return request({
    url: '/apiv2/teacher/exam/exportGradeTable',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

/**
 * 机房考试 - 按场次导出成绩单-单场直接导出(超级教师)
 * @param data
 * @returns {*}
 */
export function superExportByExam(data = {}) {
  return request({
    url: '/apiv2/teacher/exam/superExportByExam',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 机房考试 - 按场次导出成绩单-批量直接导出（超级教师）
 * @param data
 * @returns {*}
 */
export function superBatchExportByExam(data = {}) {
  return request({
    url: '/apiv2/teacher/exam/superBatchExportByExam',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 机房考试 - 按场次导出成绩单-批量直接导出（通用）
 * @param data
 * @returns {*}
 */
export function batchExportByExam(data = {}) {
  return request({
    url: '/apiv2/teacher/exam/batchExportByExam',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}



/**
 * 机房考试 - 多场次 - 合并导出（普通教师和超级教师共用）
 * @param data
 * @returns {*}
 */
export function mergeExportByExam(data = {}) {
  return request({
    url: '/apiv2/teacher/exam/mergeExportByExam',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 查询机房考试详情 - 个人报告
 * @param {String} examineeId
 * @param {String} batchNum
 * @param {String} clazzId
 * @return {AxiosPromise}
 */
export function personalReport(data) {
  return request({
    url: '/apiv2/teacher/exam/personalReport',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

/**
 * 根据考试批次号查询试题ID和试题名称
 * @param {String} batchNum
 * @return {AxiosPromise}
 */
export function getGroupListByBatchNum(data) {
  return request({
    url: '/apiv2/teacher/exam/getGroupListByBatchNum',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}


/**
 * 根据考试批次号获取考试班级列表
 * @param data
 * @returns {*}
 */
export function getClazzListByBatchNum(data = {}) {
  return request({
    url: '/apiv2/teacher/exam/getClazzList',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}


/**
 * 子作业详情 - 学生报告
 * @param {String} userId
 * @param {String} itemId
 * @return {AxiosPromise}
 */
export function studentReport(data) {
  return request({
    url: '/apiv2/teacher/homework/studentReport',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

/**
 * 查询机房考试学生分页列表
 * @param data
 * @returns {*}
 */
export function getStudentPage(data = {}) {
  return request({
    url: '/apiv2/teacher/exam/web/getStudentPage',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}
